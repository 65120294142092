module.exports = [{
      plugin: require('../../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Ulule Design System","short_name":"Ulule","start_url":"/","background_color":"#18A5D6","theme_color":"#18A5D6","display":"minimal-ui","icon":"src/images/design-system-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f4dee0f82bedf7b8604fcb5f5f8c567d"},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/kyojin/Code/ulule/frontend/owl-nest/standalone/owl-kit-website"},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["ff-tisa-web-pro:400,600,700","sofia-pro:300,400,500,600,700,900","sofia-pro-condensed:900"],"urls":["https://use.typekit.net/nyr7bbn.css"]},"google":{"families":["Open Sans:300,400,600,700,800","Source Code Pro"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
