// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-layouts-doc-layout-tsx": () => import("./../../../src/layouts/DocLayout.tsx" /* webpackChunkName: "component---src-layouts-doc-layout-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-components-tsx": () => import("./../../../src/pages/components.tsx" /* webpackChunkName: "component---src-pages-components-tsx" */),
  "component---src-pages-contributing-mdx": () => import("./../../../src/pages/contributing.mdx" /* webpackChunkName: "component---src-pages-contributing-mdx" */),
  "component---src-pages-faq-mdx": () => import("./../../../src/pages/faq.mdx" /* webpackChunkName: "component---src-pages-faq-mdx" */),
  "component---src-pages-getting-started-mdx": () => import("./../../../src/pages/getting-started.mdx" /* webpackChunkName: "component---src-pages-getting-started-mdx" */),
  "component---src-pages-how-to-use-it-mdx": () => import("./../../../src/pages/how-to-use-it.mdx" /* webpackChunkName: "component---src-pages-how-to-use-it-mdx" */),
  "component---src-pages-identity-colors-mdx": () => import("./../../../src/pages/identity/colors.mdx" /* webpackChunkName: "component---src-pages-identity-colors-mdx" */),
  "component---src-pages-identity-icons-glyphs-mdx": () => import("./../../../src/pages/identity/icons/glyphs.mdx" /* webpackChunkName: "component---src-pages-identity-icons-glyphs-mdx" */),
  "component---src-pages-identity-icons-illustrations-mdx": () => import("./../../../src/pages/identity/icons/illustrations.mdx" /* webpackChunkName: "component---src-pages-identity-icons-illustrations-mdx" */),
  "component---src-pages-identity-logotypes-mdx": () => import("./../../../src/pages/identity/logotypes.mdx" /* webpackChunkName: "component---src-pages-identity-logotypes-mdx" */),
  "component---src-pages-identity-typography-copy-mdx": () => import("./../../../src/pages/identity/typography/copy.mdx" /* webpackChunkName: "component---src-pages-identity-typography-copy-mdx" */),
  "component---src-pages-identity-typography-families-mdx": () => import("./../../../src/pages/identity/typography/families.mdx" /* webpackChunkName: "component---src-pages-identity-typography-families-mdx" */),
  "component---src-pages-identity-typography-headings-mdx": () => import("./../../../src/pages/identity/typography/headings.mdx" /* webpackChunkName: "component---src-pages-identity-typography-headings-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

